
.custom-content {
    margin-top: 18px;
    height: calc(100% - 70px);
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;

    .create-btn {
        width: 118px;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #2DC079;
        border-radius: 4px;
        color: #2DC079;
        line-height: 40px;
        cursor: pointer;
        text-align: center;
        font-size: 16px;
    }

    .custom-wrap {
        flex: 1;
        height: 1%;
        display: flex;
        flex-direction: column;

        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
            height: 100%;

            .el-scrollbar__view {
                margin: 0 20px;
                height: 100%;
                display: flex;
                flex-direction: column;
            }
        }

        .customTable {
            margin-top: 12px;
        }

        .item-content {
            display: flex;
            align-items: center;

            .item-left {
                width: 80px;
                height: 80px;

                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            .item-right {
                margin-left: 20px;

                .item-title {
                    width: 200px;
                    color: #333333;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }
        }

        .pages-center {
            margin-bottom: 0;
        }
    }

    .record-content {
        margin-top: 30px;

        &:first-child {
            margin-top: 0;
        }

        .item-title {
            font-size: 16px;

            &.question {
                color: #2DC079;
            }

            &.answer {
                color: #2461EF;
                margin-top: 30px;
            }
        }

        .item-content {
            margin-top: 16px;
            font-size: 16px;
        }
    }
}
